














import Vue from "vue";
import { mdiFullscreen, mdiPlus, mdiMinus } from "@mdi/js";

export default Vue.extend({
  name: "ImagesFieldMobileNav",
  data: () => ({
    svgFullscreen: mdiFullscreen,
    svgPlus: mdiPlus,
    svgMinus: mdiMinus
  })
});
